<template>
  <v-card
    outlined
    rounded="lg"
    max-height="calc(100vh - 48px - 32px)"
    min-height="100%"
    >
    <v-card-text
      class="black--text px-0"
      >
      <div
        class="text-center"
        >
        <div
          class="mb-6"
          style="position: relative"
          >
          <v-avatar
            v-if="fair.image && fair.image.url"
            color="primary"
            size="130"
            >
            <v-img
              :src="url + fair.image.url"
              ></v-img>
          </v-avatar>

          <v-avatar
            v-else
            color="secondary"
            size="130"
            >
            <v-icon
              color="white"
              size="60"
              >
              mdi-string-lights
            </v-icon>
          </v-avatar>

          <v-btn
            fab
            x-small
            style="position: absolute; right: calc(50% - 16px); bottom: -16px; z-index: 2"
            @click="openChangePicture"
            >
            <v-icon
              color="primary"
              >
              mdi-camera
            </v-icon>
          </v-btn>

          <input
            class="d-none"
            ref="picture"
            type="file"
            accept="image/*"
            @change="uploadPicture"
            ></input>
        </div>

        <div
          class="text-h6 text-center mt-2 black--text font-weight-bold"
          >
          {{ fair.name }}
        </div>

        <div
          class="body-1 black--text"
          >
          {{ (new Date(fair.startsAt)).toLocaleDateString() }} al {{ (new Date(fair.endsAt)).toLocaleDateString() }}
        </div>

        <div
          class="body-1 black--text mt-2"
          >
          <v-icon color="primary" class="mt-n1">mdi-account</v-icon> {{ fair.owner }}
        </div>

        <div
          class="px-2 mt-2"
          >
          <v-checkbox
            dense
            hide-details
            v-model="active"
            color="primary" 
            >
            <template v-slot:label>
              <div
                class="black--text"
                >
                Activar feria 
                <v-tooltip 
                  color="info"
                  bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="info"
                      dark
                      size="20"
                      style="margin-top: -2px"
                      v-bind="attrs"
                      v-on="on"
                      >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>La feria está lista para que las tiendas comiencen a inscribirse.</span>
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>

      <v-list
        nav
        dense
        class="px-0 mt-3"
        >
        <v-list-item-group
          v-model="selected"
          mandatory
          color="primary"
          >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider></v-divider>

        <v-list-item
          @click="redirect"
          >
          <v-list-item-icon>
            <v-icon>mdi-string-lights</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ver feria</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { EditFair } from '@/graphql/mutations/fairs/fairs'

export default {
  data: () => ({
    selected: 0,
    active: false,
    items: [
      {
        title: 'Datos básicos',
        icon: 'mdi-cog',
      },
      {
        title: 'Banners',
        icon: 'mdi-image-outline',
      },
      {
        title: 'Sponsors',
        icon: 'mdi-handshake-outline',
      },
      {
        title: 'Tiendas',
        icon: 'mdi-storefront-outline',
      },
      {
        title: 'Analíticas',
        icon: 'mdi-chart-bar',
      },
    ]
  }),

  created () {
    this.active = this.fair.active
  },

  computed: {
    ...mapGetters(['url'])
  },

  watch: {
    selected (val) {
      this.$emit('select', val)
    },

    fair: {
      deep: true,

      handler () {
        this.active = this.fair.active
      }
    },

    active () {
      this.changeActive()
    }
  },

  methods: {
    openChangePicture () {
      this.$refs.picture.click()
    },

    changeActive () {
      if (this.active != this.fair.active)
        this.$apollo.mutate({
          mutation: EditFair,
          variables: {
            input: {
              attributes: {
                active: this.active
              },
              id: this.fair.id
            }
          }
        }).then ( res => {
          this.$emit('reload')
        })
    },

    uploadPicture (e) {
      var file = e.target.files[0]

      var imageAttributes = {
        name: file.name,
        size: file.size,
        image: file,
      }

      this.$apollo.mutate({
        mutation: EditFair,
        variables: {
          input: {
            attributes: {
              imageAttributes: imageAttributes
            },
            id: this.fair.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
      })
    },

    redirect () {
      const routeData = this.$router.resolve({ name: 'fair', params: { id: this.$route.params.id } });
      window.open(routeData.href, '_blank');
    }
  },

  props: {
    fair: {
      required: true,
      type: Object
    }
  }
}
</script>
